var render = function render(){var _vm=this,_c=_vm._self._c;return _c('details',{ref:"followingDetailsRef",staticClass:"following"},[_c('summary',{staticClass:"following__summary"},[(_vm.following && _vm.watching)?_c('span',{staticClass:"a-button is-warning is-sm is-block"},[_c('i',{staticClass:"fa-solid fa-check"}),_c('span',[_vm._v("コメントあり")])]):(_vm.following && !_vm.watching)?_c('span',{staticClass:"a-button is-warning is-sm is-block"},[_c('i',{staticClass:"fa-solid fa-check"}),_c('span',[_vm._v("コメントなし")])]):_c('span',{staticClass:"a-button is-secondary is-sm is-block"},[_vm._v(_vm._s(_vm.buttonLabel))])]),_c('div',{staticClass:"following__dropdown a-dropdown"},[_c('ul',{staticClass:"a-dropdown__items"},[_c('li',{staticClass:"following__dropdown-item a-dropdown__item"},[(_vm.following && _vm.watching)?_c('button',{staticClass:"following-option a-dropdown__item-inner is-active"},[_vm._m(0)]):_c('button',{staticClass:"following-option a-dropdown__item-inner",on:{"click":function($event){return _vm.followOrChangeFollow(true)}}},[_vm._m(1)])]),_c('li',{staticClass:"following__dropdown-item a-dropdown__item"},[(_vm.following && !_vm.watching)?_c('button',{staticClass:"following-option a-dropdown__item-inner is-active"},[_vm._m(2)]):_c('button',{staticClass:"following-option a-dropdown__item-inner",on:{"click":function($event){return _vm.followOrChangeFollow(false)}}},[_vm._m(3)])]),_c('li',{staticClass:"following__dropdown-item a-dropdown__item"},[(!_vm.following)?_c('button',{staticClass:"following-option a-dropdown__item-inner is-active"},[_vm._m(4)]):_c('button',{staticClass:"following-option a-dropdown__item-inner",on:{"click":_vm.unfollow}},[_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"following-option__inner"},[_c('div',{staticClass:"following-option__label"},[_vm._v("コメントあり")]),_c('div',{staticClass:"following-option__desciption"},[_vm._v("フォローしたユーザーの日報を自動でWatch状態にします。日報投稿時の通知と日報にコメントが来た際に通知を受け取ります。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"following-option__inner"},[_c('div',{staticClass:"following-option__label"},[_vm._v("コメントあり")]),_c('div',{staticClass:"following-option__desciption"},[_vm._v("フォローしたユーザーの日報を自動でWatch状態にします。日報投稿時の通知と日報にコメントが来た際に通知を受け取ります。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"following-option__inner"},[_c('div',{staticClass:"following-option__label"},[_vm._v("コメントなし")]),_c('div',{staticClass:"following-option__desciption"},[_vm._v("フォローしたユーザーの日報はWatch状態にしません。日報投稿時の通知だけ通知を受けとります。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"following-option__inner"},[_c('div',{staticClass:"following-option__label"},[_vm._v("コメントなし")]),_c('div',{staticClass:"following-option__desciption"},[_vm._v("フォローしたユーザーの日報はWatch状態にしません。日報投稿時の通知だけ通知を受けとります。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"following-option__inner"},[_c('div',{staticClass:"following-option__label"},[_vm._v("フォローしない")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"following-option__inner"},[_c('div',{staticClass:"following-option__label"},[_vm._v("フォローしない")])])
}]

export { render, staticRenderFns }