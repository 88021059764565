import { render, staticRenderFns } from "./watch-toggle.vue?vue&type=template&id=537a6644&scoped=true&lang=pug"
import script from "./watch-toggle.vue?vue&type=script&lang=js"
export * from "./watch-toggle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537a6644",
  null
  
)

export default component.exports