<template lang="pug">
.thread-comments.is-only-mentor
  header.thread-comments__header
    h2.thread-comments__title
      | AIによって生成された解答
    .a-form-help
      p
        | この機能はメンターにだけ公開されている機能です。
        | この機能が受講生にとって役に立つものになるか、
        | 逆に受講生を混乱させてしまわないかなどの検証を行った上で、
        | 全体に公開するかの判断をします。
  .thread-comments__items
    .thread-comment
      .thread-comment__start
        .thread-comment__user-link
          .a-user-role
            img.thread-comment__user-icon.a-user-icon(
              src='/images/users/avatars/open_ai.svg')
      .thread-comment__end
        .a-card.is-answer
          .thread-comment__description
            .a-long-text.is-md(v-html='markdownDescription')
</template>
<script>
import MarkdownInitializer from 'markdown-initializer'

export default {
  name: 'AIAnswer',
  props: {
    text: { type: String, required: true }
  },
  computed: {
    markdownDescription() {
      const markdownInitializer = new MarkdownInitializer()
      return markdownInitializer.render(this.text)
    }
  }
}
</script>
